:root {
  --color-aqua: rgb(2,193,178);
  --color-pale-pink: rgb(238,204,233);
  --color-salmon: rgb(240,113,170);
  --color-slate: rgb(216,238,226);
  --color-yellow: rgb(255,251,160);

  --text-color-dark: rgb(44, 44, 44);
  --text-color-light: rgb(235, 235, 235);
}

* {
  box-sizing: border-box;
}

html {
  font-family: -apple-system,
               BlinkMacSystemFont,
               'Segoe UI',
               Roboto,
               Oxygen,
               Ubuntu,
               Cantarell,
               'Open Sans',
               'Helvetica Neue',
               sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.masthead {
  left: 0;
  margin: 1em;
  position: fixed;
  top: 0;
}

.incidents {
  display: flex;
  flex-flow: row wrap;
}
